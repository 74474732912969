.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.active-color {
  color : #378CE7;
}
.active2-color {
  color : #DFF5FF
}
.light-color {
  color :#FFFFFF
}
.basic-color {
  color : #222222
}
.basic-greyout {
  color : #EEEEEE
}
.custom-button {
  background-color: #378CE7;
  color :#FFFFFF
}
.chatboxbtn {
  position: fixed;
  z-index: 1;
  bottom: 20px;
  right: 20px;
}
.chatbox {
  position: fixed;
  z-index: 1;
  bottom: 72px;
  right: 20px;
  display: block !important;
}
.tooltip-inner {
  max-width: 500px; 
  white-space: pre-wrap; 
  text-align: start;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
